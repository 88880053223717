<template>
  <div class="div-clas mb-5 " style="margin-top:-5rem !important;">
      <div class="bg-creweb ">
        <div class="container-fluid margin-text-titulo-creweb ">
          <div class="row" style="margin-top:6%;">
            <img style="margin-top:8rem !important;" class="img-header-creweb-movil" src="../assets/Nosotros/icono_principal.png" alt="" data-aos="fade-down" data-aos-delay="100"  data-aos-easing="linear">
            <div class="col-md-5 offset-md-1">
              <h1 class="text-titulo-header-creweb animate__animated  animate__bounceInDown animate__fast "><b>CREACIÓN DE  PÁGINA WEB </b></h1>
              <div class="tamaño-div-creweb"><p class="text-header-creweb mt-5" data-aos="fade-down" data-aos-delay="100"  data-aos-easing="linear">Creamos tu página web de froma personalizada, adaptándole a la medida de tus necesidades e indentidad de marca. El tiempo de diseño de la página web es de 5 dias hábiles. </p></div>
              <p class="text-creweb " data-aos="fade-down" data-aos-delay="200"  data-aos-easing="linear">*Servicio no disponible en modalidad individual. Se trabaja exclusivamente en cualquiera de nuestros paquetes. </p>
            
            </div>
            <div class="col-md-6">
              <img class="img-header-creweb mb-5" src="../assets/Nosotros/icono_principal.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 offset-md-1 text-align-l-25k">
            <img class="img-creweb-movil" src="../assets/Servicios/Creación_páginas_web/ecommerce.png" alt="">
              <h1 class="text-titulo-creweb" data-aos="fade-down" data-aos-delay="100"  data-aos-easing="linear"><b>Ecommerce</b></h1>
              <p class="p-creweb mt-4" data-aos="fade-down" data-aos-delay="300"  data-aos-easing="linear">Para el caso de ecommerce, utilizamos shopify, pues este incluye integraiones con email marketing, códigos de descuento, programas de fidelización de clientes, entre otros atributos, que nos ayudarán a crecer las ventas de tu negocio en tiempo record.</p>
          </div>
          <div class="col-md-4 offset-md-1">
            <div class=""><img class="img-creweb" data-aos="fade-down" data-aos-delay="200"  data-aos-easing="linear" src="../assets/Servicios/Creación_páginas_web/ecommerce.png" alt=""></div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-5">
        <div class="row color-bakground-creweb" >
          <div class="col-md-5" data-aos="fade-down" data-aos-delay="100"  data-aos-easing="linear">
            <img class="img-tasa-bateo" src="../assets/Servicios/Creación_páginas_web/tasa_bateo.png" alt="">
          </div>
          <div class="col-md-5 offset-md-1" data-aos="fade-down" data-aos-delay="200"  data-aos-easing="linear">
            <h1 class="text-white-creweb p-1 m-2"><b> Disminuye tu tasa de bateo</b></h1>
            <div><p class="text-tasa-bateo">  Buscamos  que tu sitio web pueda vender por si solo y la tasa de bateo sea minima (la tasa de bateo nos dice indica cuántas de las personas que vieron tu página, se salieron sin realizar ninguna acción).</p></div>
          </div>
        </div>
        <div>
          <h1 class="text-titulo-tasa-bateo" data-aos="zoom-in" data-aos-delay="100"  data-aos-easing="linear"><b> ¿Qué necesitamos para empezar?</b></h1>
          <p class="text-p-tasa-bateo">Mándanos 3 diseños que te gusten para basarnos en ellos y el acceso <br> a tu plataforma o servidor en donde tengas tu página actualmente</p>
          <h1 class="text-titulo-tasa-bateo"><b>Conoce nuestro trabajo</b> </h1>        
        </div>
        <div class="container-fluid">
          <div class="row">
            <img class="img-inmobina-movil img-fluid" src="../assets/img-techsoft/mo.png" alt="">
            <div class="col-md-7 div-vendemas text-align-l-vendemas">
                <h1 class="text-titulo-vendemas" data-aos="zoom-in" data-aos-delay="100"  data-aos-easing="linear"><b>Modo Orgánico</b></h1>
                <p class="p-vendemas mt-4" data-aos="zoom-in" data-aos-delay="200"  data-aos-easing="linear">Es una empresa ubicada en Perú que fomenta un estilo de vida saludable y consciente con el planeta. Modo Orgánico ofrece productos altamente nutritivos, ricos y que sean fáciles de incorporar en el estilo de vida de cada uno.</p>
            </div>
            <div class="col-md-4">
              <div class=""><img class="img-inmobina img-fluid" data-aos="zoom-in"   data-aos-easing="linear" src="../assets/img-techsoft/mo.png" alt=""></div>
            </div>
          </div>
        </div>
      </div>
      <img class="img-fluid img-modo-organico-movil" src="../assets/img-techsoft/mo-pc2.png" alt="">
      <img class="img-fluid img-modo-organico" src="../assets/img-techsoft/mo-pc2.png" alt="">
       <b-button @click="$router.push('/f-vendemas')" class="button-creweb animate__animated animate__zoomIn mt-3 mb-3" variant="primary"><span class="text-button-nosotros2">Ver portafolio</span> </b-button>
      <p class="mb-5"><b> *Servicio no disponible en modalidad individual. Se trabaja en cualquiera de nuestros paquetes. </b></p> <br>
      <!-- <div>
        <a class="btn btn-outline-primary btn-lg pill mt-4 shadow"  href="mailto:ventas@techsoftconsulting.mx">
        <span>
          <span class="h6 mr-2 font-weight-bold">Contactenos</span>
          <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
        </span>
      </a>
      </div> -->
      <a class="btn btn-outline-primary btn-lg pill mt-4 shadow"  href="mailto:ventas@techsoftconsulting.mx">
        <span>
          <span class="h6 mr-2 font-weight-bold">Contactenos</span>
          <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
        </span>
      </a>
  </div> 
  
</template>

<script>
export default {

}
</script>

<style >
.pill {
  
  
  padding: 10px 20px;
  text-align: center;
  
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
}
.img-modo-organico-movil{
  display: none;
}
.img-inmobina-movil{
  display: none;
}
.img-creweb-movil{
  display: none;
}
.img-creweb{
  display: inline;
}
.img-header-creweb-movil{
    display: none;
  }
.img-header-creweb{
  display: inline;
}
.f-creweb{
  height: 500px;
  margin-bottom: 20%;
  margin-top: -1%;
}

.bg-creweb{ 
  /* The image used */
  background-image: url("../assets/Inicio/principal.png");
  margin-top: 5%;
  height: 130%; 


  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-modo-organico{
  display: inline;
  width: 90%;
}


@-webkit-keyframes mover2 {
    1% { transform: translateX(1px); }
    50% { transform: translateY(-15px); }
}

@media only screen and (max-width: 770px) {
    .img-modo-organico{
  display: none;
  }
  .img-modo-organico-movil{
  display: inline;
}
  .img-inmobina{
  display: none;
}
  .img-inmobina-movil{
  display: inline;
    width: 40%;
    margin-top: 5%;
    margin-bottom: 5%; 
    margin-left: 30%;
}
  .img-header-creweb-movil{
   display: inline;
    width: 40%;
    margin-top: 8%;
    margin-bottom: 3%;
    margin-left: 30%;
  }
  .img-header-creweb{
  display: none;
}
.text-titulo-header-creweb{
    color: rgb(255, 255, 255);
    font-size: 47px !important;
    width: 100%;
    text-align: center;

}
.text-header-creweb{
    color: rgb(255, 255, 255);
    font-size: 20px!important;
    text-align: justify;
    padding: 5%;
    margin-top: 0px;
  }
  .text-creweb{
  font-size: 0.8rem !important;
  margin-top: 0px;
  margin-bottom: 50;
  margin-left: 5%;
}
.img-creweb-movil{
  display: inline;
  width: 50%;
    margin-top: 8%;
    margin-bottom: 3%;
    margin-left: 20%;
}
.img-creweb{
  display: none;
}
.text-titulo-creweb{
  font-size: 1.6rem !important;
    text-align: center;
}
.text-white-creweb{
  font-size: 1.6rem !important;
    text-align: center;
}
.p-creweb{
    font-size: 1.2rem !important;
    width: 100%;
  }
  .text-tasa-bateo{
    font-size: 1.2rem !important;
    width: 100%;
    text-align: justify ;
  }
  .text-titulo-tasa-bateo{
    font-size: 1.6rem !important;
    text-align: center;
  }
  .text-p-tasa-bateo{
    font-size: 1.2rem !important;
    width: 100%;
    text-align: center ;
  }
  .text-titulo-vendemas{
  font-size: 1.6rem !important;
    text-align: center;
}
.p-vendemas{
    font-size: 1.2rem !important;
    width: 100%;
  }
  .button-creweb{
    width: 75%;
    height: 10%;
    margin-bottom: 15%;
    margin-left: 10%;
    margin-left: 0px;
  }
  .text-button-nosotros2{
    font-size:15px !important;
    }

}
@media only screen and (max-width: 1070px) and (min-width: 720px) {
  .text-titulo-header-creweb, .img-header-creweb{
    margin-top: 3rem !important;
    
  }
  
}

</style>